<template>
  <div class="del-wrap">
    <el-dialog title="拒绝"
               :visible="refuseDialogVisible"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false"
               width="500px"
               center>
      <div class="content-wrap">
        <p>是否拒绝提现请求？</p>
        <el-input type="textarea"
                  :autosize="{ minRows: 4, maxRows: 6}"
                  placeholder="拒绝理由..."
                  v-model="description">
        </el-input>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="cancleDialog">取 消</el-button>
        <el-button type="primary"
                   @click="submitDialog">拒 绝</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    refuseDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      description: ''
    }
  },
  methods: {
    cancleDialog () {
      this.$emit('cancleDialog', 'refuseDialogVisible')
    },
    submitDialog () {
      this.$emit('submitRefuseDialog', { status: 2, description: this.description })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  text-align: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.del-wrap /deep/ button.el-button.el-button--default {
  width: 85px;
  height: 32px;
}
.content-wrap {
  padding: 0 70px;
  text-align: left;
  p {
    margin-bottom: 22px;
  }
}
</style>